const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL || "/api/",
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://localhost:5000/api/',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  multipartHeaders: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  stripePK: "pk_live_51NVlFuGWdi5ohFr7RhMFnFbijo8ItpbwQmDUJ6lKvTqNykOyg1UqEfhVcyrcrNhqkODacto3z5apOmbPJOpWROQw007d987TM2",
  // stripePK: "pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu",
  SOCKET_URL: "/",
  // SOCKET_URL: 'http://localhost:5000/',
};

export default constants;
