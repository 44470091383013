import { createSlice } from "@reduxjs/toolkit";
import { setStorage } from "../../helpers";

const initialState = {
    resultPath: '',
    id: ''
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setResultPath(state, action) {
            state.resultPath = action.payload.path;
            state.id = action.payload.id
        },
    },
});

export const { setResultPath } = appSlice.actions;

export default appSlice.reducer;
