import { lazy } from 'react';

const routes = [
  {
    path: "",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  {
    path: "thank-you",
    component: lazy(() => import("../pages/Home/Thanks")),
    exact: true,
  },
  {
    path: "admin/analyze/secure",
    component: lazy(() => import("../pages/Admin/Users")),
    exact: true,
  },
  {
    path: "admin/photos/secure/:type/:email?",
    component: lazy(() => import("../pages/Admin/Photos")),
    exact: true,
  },
  {
    path: "promo/:promo",
    component: lazy(() => import("../pages/Home/Home")),
    exact: true,
  },
  // {
  //   path: 'admin/users/:id/history',
  //   component: lazy(() => import('../pages/Home/Home')),
  //   exact: true,
  // },
  // {
  //   path: 'plans',
  //   component: lazy(() => import('../pages/Price/Lists')),
  //   exact: true,
  // },
  // {
  //   path: 'plans/:slug',
  //   component: lazy(() => import('../pages/Price/Subscription')),
  //   exact: true,
  // },
  // {
  //   path: 'user/profile',
  //   component: lazy(() => import('../pages/Profile/Edit')),
  //   exact: true,
  // },
  // {
  //   path: 'admin/users',
  //   component: lazy(() => import('../pages/Admin/Users')),
  //   exact: true,
  //   isAdmin: true,
  // },
];

export default routes;
